<template>
  <div class="main-box">
    <div class="container">
      <!-- 企业私域运营和管理痛点  h455-->
      <div class="pain-spot">
        <h2 class="T-text">企业私域运营和管理痛点</h2>
        <ul class="tagBox">
          <li>
            <div>
              <img
                src="../assets/img/组 53.png"
                style="width: 83px; height: 64px"
              />
            </div>
            <p>缺乏高效引流工具 私域沉淀难</p>
          </li>
          <li>
            <div>
              <img
                src="../assets/img/组 13.png"
                style="width: 83px; height: 64px"
              />
            </div>
            <p>缺乏标准运营工具 人力成本高</p>
          </li>
          <li>
            <div>
              <img
                src="../assets/img/组 99.png"
                style="width: 83px; height: 64px"
              />
            </div>
            <p>难以洞察客户需求 留存转化低</p>
          </li>
          <li>
            <div>
              <img
                src="../assets/img/组 7.png"
                style="width: 83px; height: 64px"
              />
            </div>
            <p>个微私域工具受管控 使用风险高</p>
          </li>
        </ul>
      </div>
      <!-- 增值赋能，让私域流量运营真正落地 h1200 -->
      <div class="incpxent">
        <div class="T-box">
          <h2 class="T-text">增值赋能，让私域流量运营真正落地</h2>
          <p>多种方式和手段帮助企业解决私域流量的布局和落地</p>
        </div>

        <div class="incpxent-img"></div>
      </div>
    </div>
    <!-- 精细化社群运营 -->
    <div class="meticulous">
      <div class="container">
        <div class="T-box">
          <h2 class="T-text">精细化社群运营</h2>
          <p>社群标签分层，多种方式建群，群SOP精准推送，实现运营社群高效运营</p>
        </div>

        <ul>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 107.png" />
            </div>
            <p>群SOP标准化推送流程</p>
          </li>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 108.png" />
            </div>
            <p>多种方式高效建群</p>
          </li>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 109.png" />
            </div>
            <p>客户群聊内容质检提醒</p>
          </li>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 112.png" />
            </div>
            <p>群标签分类管理</p>
          </li>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 111.png" />
            </div>
            <p>群统计掌握数据</p>
          </li>
          <li>
            <div class="li-img">
              <img src="../assets/img/组 110.png" />
            </div>
            <p>客户群群发高效运营</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="container">
      <!-- 企微导购系统 -->
      <div class="shopping-guide">
        <h2 class="T-text">企微导购系统</h2>
        <div class="shopping-guide-bg">
          <img
            src="../assets/img/14c7f92acafe667d54adb1572d13c114_25gNoBeIAAAAAElFTkSuQmCC.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- 沉淀客户数据资产 -->
    <div class="sediment" :style="`height:${swiperHeight}px`">
      <!-- <img
        src="../assets/img/pic_1.png"
        style="width: 100%; height: auto;opacity:0"
        alt=""
      /> -->
      <el-carousel style="height: 100%">
        <el-carousel-item
          v-for="(item, index) in imgList"
          :key="index"
          class="carousel-item"
        >
          <img
            :src="item.url"
            alt=""
            @load="loadImg"
            style="width: 100%; height: auto"
            class="carousel-img"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 私域流量、企业微信、社群运营如何闭环， 快速驱动业务增长？ -->
    <div class="flow">
      <img
        src="../assets/img/bg.png"
        style="width: 100%; height: auto"
        alt=""
      />
      <div class="container position">
        <div class="flow-box">
          <div class="flow-top">
            <span> 私域流量、企业微信、社群运营如何闭环， </span>
            <span> 快速驱动业务增长？ </span>
          </div>
          <div class="flow-bottom">
            <span>
              2018-2020年，3个词的百度指数分别增长 私域流量 580% 企业微信 385%
            </span>
            <span> 社群运营 225% </span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- 私域商城 -->
      <div class="mall">
        <div class="T-box">
          <h2 class="T-text">私域商城</h2>
          <p>打造千店千面，线上一个店线下一个店，不同门店的线上店不同</p>
          <p class="m-t5">
            门店可由渠道代维护商品、售价、导购员佣金以及活动，形成千店千面个性化营销
          </p>
        </div>

        <div class="mall-bg"></div>
      </div>
      <!-- 服务客户 -->
      <div class="service">
        <div class="T-box">
          <h2 class="T-text">服务客户</h2>
          <p>
            广泛覆盖银行、大健康、母婴、连锁餐饮、快消品等行业，赋能私域服务新价值
          </p>
        </div>
        <div class="service-bg"></div>
      </div>
      <!-- 了解更多行业解决方案 -->
      <div class="more">
        <h2 class="T-text">了解更多行业解决方案</h2>
        <ul>
          <li>
            <div class="more-img">
              <img src="../assets/img/1-11.png" alt="" />
            </div>
            <h3>新零售</h3>
            <p>
              营销自动化系统让零售品牌更深入洞悉消费者多样化需求，在降低营销成本的同时，创造更高的消费转化率
            </p>
            <el-button type="text" class="btn" @click="dialogVisible = true"
              >了解更多</el-button
            >
          </li>
          <li>
            <div class="more-img">
              <img
                src="../assets/img/clem-onojeghuo-222414-unsplash.png"
                alt=""
              />
            </div>
            <h3>餐饮连锁</h3>
            <p>
              中国餐饮业市场规模持续壮大，坚持以人为本、服务民生的原则，未来餐饮行业将会向着联网化、零售化、数字化方向发展人们对餐饮需求变化加快餐饮种类发展，餐饮资源整合衍生更多餐饮品牌
            </p>
            <el-button type="text" class="btn" @click="dialogVisible = true"
              >了解更多</el-button
            >
          </li>
          <li>
            <div class="more-img">
              <img
                src="../assets/img/sean-pollock-203658-unsplash.png"
                alt=""
              />
            </div>
            <h3>商业综合体</h3>
            <p>
              营销自动化系统让零售品牌更深入洞悉消费者多样化需求，在降低营销成本的同时，创造更高的消费转化率
            </p>
            <el-button type="text" class="btn" @click="dialogVisible = true"
              >了解更多</el-button
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="open">
        <span>您好，我是您的专属客服</span>
        <img src="../assets/img/erweima.png" alt="" />
        <p>欢迎添加客服企业微信, 了解更多详情！</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      swiperHeight: 500,
      imgList: [
        { url: require("../assets/img/pic_1.png") },
        { url: require("../assets/img/pic_2.png") },
        { url: require("../assets/img/pic_3.png") },
        { url: require("../assets/img/pic_4.png") },
      ],
    };
  },

  methods: {
    loadImg(e) {
      this.swiperHeight = e.path[0].clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box /deep/ .el-carousel__container {
  position: relative;
  height: 100%;
}

.main-box /deep/ .el-dialog {
  width: 360px;
  border: 1px solid #fff;
  // background: none;
  box-shadow: none;
}
.main-box /deep/ .el-dialog__header {
  width: 360px;
  overflow: hidden;
  box-sizing: border-box;
}
.main-box /deep/ .el-dialog__body {
  padding: 0;
}
.main-box /deep/ .el-dialog__headerbtn {
  // background-color: black;
  right: 30px;
}
.open {
  width: 360px;
  height: 420px;
  // border: 1px solid springgreen;
  // background-image: url("../assets/img/蒙版组 14.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  span {
    width: 100%;
    height: 29px;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: black;
    line-height: 29px;
    letter-spacing: 1px;
    // background-color: steelblue;
  }
  img {
    width: 180px;
    height: 180px;
    border: 2px solid #ffffff;
  }
  p {
    width: 170px;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: black;
    line-height: 24px;
    letter-spacing: 1px;
  }
}

// .main-box /deep/ .el-carousel__indicator {
// background-color: #fdd000;
// cursor: pointer;
// }
// .main-box /deep/ .el-carousel__button {
//     display: block;
//     opacity: .48;
//     width: 30px;
//     height: 2px;
//     background-color: black;
//     border: none;
//     outline: 0;
//     padding: 0;
//     margin: 0;
//     cursor: pointer;
//     transition: .3s;
// }
// .main-box .el-carousel__indicators--horizontal /deep/ .is-active{
//   background-color: olive;

// }

.main-box {
  font-size: 16px;
  .pain-spot {
    width: 100%;
    height: 455px;
    // background-color: sienna;
    overflow: hidden;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    h2 {
      // border: 1px solid #fff;
      margin-top: 86px;
      margin-bottom: 66px;
    }
    .tagBox {
      width: 100%;
      height: 256px;
      // border: 1px solid springgreen;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      li {
        width: 290px;
        height: 140px;
        border: 1px solid #006eff;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-shadow: 0 3px 18px 0 rgba(4, 32, 109, 0.1);
        align-items: center;
        // animation: all .3;
        div {
          width: 83px;
          height: 64px;
        }
        p {
          width: 140px;
          height: 45px;
          line-height: 25px;
          color: #191919;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
      // li:hover {

      // margin-top: -30px;
      // transform: translateY(-14%);
      // animation: all 1000;
      // }
    }
  }
  .incpxent {
    width: 100%;
    height: 1200px;
    // background-color: slateblue;
    overflow: hidden;
    div {
      height: 100px;
    }
    // div {
    //   height: 100px;
    //   text-align: center;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    //   // border: 1px solid snow;
    //   p {
    //     color: #737373;
    //     font-size: 18px;
    //     margin-top: 30px;
    //   }
    // }
    .incpxent-img {
      width: 100%;
      height: 1130px;
      // border: 1px solid springgreen;
      background-image: url("../assets/img/组 166.png");
      background-repeat: no-repeat;
      background-size: 1200px 1100px;
    }
  }
  .meticulous {
    width: 100%;
    height: 647px;
    background-color: rgb(242, 248, 255);
    overflow: hidden;
    div {
      // border: 1px solid khaki;
      h2 {
        margin-top: 97px;
        // border: 1px solid khaki;
      }
    }

    ul {
      width: 1200px;
      height: 446px;
      // border: 1px solid olive;
      // background: orange;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      li {
        width: 400px;
        height: 130px;
        // border: 1px solid springgreen;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        overflow: hidden;
        .li-img {
          width: 80px;
          height: 80px;
          overflow: hidden;
        }
        p {
          width: 100%;
          height: 26px;
          font-size: 20px;
          font-weight: 400;
          color: #191919;
          line-height: 26px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .shopping-guide {
    width: 100%;
    height: 1490px;
    overflow: hidden;
    h2 {
      margin-top: 77px;
      margin-bottom: 93px;
    }
    .shopping-guide-bg {
      width: 100%;
      height: calc(1490px - 124px - 93px);
      overflow: hidden;
    }
  }
  .sediment {
    width: 100%;
    // height: auto;
    // background-color: lightcoral;
    // overflow: hidden;
    // background-image: url("../assets/img/pic_1.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // opacity: ;
  }
  .flow {
    width: 100%;
    height: auto;
    overflow: hidden;
    color: #ffffff;
    position: relative;
    .flow-box {
      // border: 1px solid springgreen;
      .flow-top {
        // margin-top: 20px;
        margin-bottom: 28px;
        // border: 1px solid #fff;
        width: 619px;
        height: 85px;
        font-size: 30px;
        span {
          display: block;
          font-weight: 400;
          line-height: 45px;
          letter-spacing: 1px;
        }
      }
      .flow-bottom {
        // border: 1px solid #fff;
        width: 422px;
        height: 60px;
        font-size: 17px;
        span {
          letter-spacing: 1px;
          font-weight: 300;
          line-height: 29px;
        }
      }
    }
  }
  .mall {
    // height: 1100px;
    // background-color: lightgrey;
    overflow: hidden;
    // box-sizing: border-box;
    padding-top: 65px;
    .mall-bg {
      width: 100%;
      height: 446px;
      // border: 1px solid snow;
      margin-top: 90px;
      margin-bottom: 162px;
      background-image: url("../assets/img/组 496.png");
      background-repeat: no-repeat;
      background-size: 100% 446px;
    }
  }
  .service {
    // height: 670px;
    // background-color: lightskyblue;
    overflow: hidden;
    .service-bg {
      margin-top: 90px;
      margin-bottom: 192px;
      width: 100%;
      height: 425px;
      background-image: url("../assets/img/组 517.png");
      background-repeat: no-repeat;
      background-size: 100% 425px;
    }
  }
  .more {
    // height: 886px;
    // background-color: lightyellow;
    // overflow: hidden;
    ul {
      width: 100%;
      height: 460px;
      margin-top: 65px;
      margin-bottom: 79px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li {
        // animation: all 0.5s;
        width: 360px;
        height: 460px;
        border: 1px #fff solid;
        border-radius: 6px;
        box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.16);
        // overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        .more-img {
          width: 360px;
          height: 188px;
          // background-image: url("../assets/img/1-11.png");
          overflow: hidden;
          // background-repeat: no-repeat;
          // background-size: 100% 100%;
        }
        h3 {
          height: 31px;
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          color: #191919;
          line-height: 40px;
          letter-spacing: 1px;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        p {
          width: 314px;
          height: 99px;
          font-size: 14px;
          font-weight: 300;
          text-align: center;
          color: #747374;
          line-height: 30px;
          letter-spacing: 1px;
          // margin: 0 auto;
        }
        .btn {
          width: 138px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #191919;
          border-radius: 23px;
          // margin: 20px auto;
          margin-top: 20px;
        }
        // .btn:hover{

        // }
      }
      li:hover {
        animation: pulse 0.5s;
      }
    }
  }
}
.T-text {
  width: 100%;
  height: 47px;
  line-height: 47px;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #333;
  letter-spacing: 2px;
}
.T-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // border: 1px solid snow;
  h2 {
    margin-bottom: 22px;
  }
  p {
    color: #737373;
    font-size: 18px;
    height: 23px;
    font-weight: 300;
    color: #747374;
    line-height: 23px;
    letter-spacing: 1px;
  }
}
.m-t5 {
  margin-top: 5px;
}
.position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

// .carousel-item {
//   width: 100%;
//   height: 100%;
//   background: white;
//   display: flex;
//   justify-content: center;
// }
// .carousel-img {
//   max-width: 100%;
//   max-height: 100%;
// }
</style>