<template>
  <div v-cloak>
    <cephalosome />
    <main-body />
    <tail/>
  </div>
</template>

<script>
import Cephalosome from "../components/cephalosome.vue";
import MainBody from "../components/mainBody.vue";
import Tail from "../components/common/tail.vue";


export default {
  components: {
    Cephalosome,
    MainBody,
    Tail
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
</style>