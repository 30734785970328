<template>
  <div class="main-box">
    <img
      src="../assets/img/banner.png"
      style="width: 100%; hieght: auto"
      alt=""
    />

    <div class="container position">
      <tabbar />

      <div class="bottom-box">
        <h2>赢客通SCRM私域流量客户关系管理运营平台</h2>
        <p>助力企业私域引流、运营、增长</p>
        <el-button
          type="text"
          @click="dialogVisible = true"
          class="btn animate__jackInTheBox animate__animated"
          >立即咨询
        </el-button>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="open">
        <span>您好，我是您的专属客服</span>
        <img src="../assets/img/erweima.png" alt="" />
        <p>欢迎添加客服企业微信, 了解更多详情！</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tabbar from "./common/tabbar.vue";

export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {
    Tabbar,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.main-box /deep/ .el-dialog {
  width: 360px;
  border: 1px solid #fff;
  // background: none;
  box-shadow: none;
}
.main-box /deep/ .el-dialog__header {
  width: 360px;
  overflow: hidden;
  box-sizing: border-box;
}
.main-box /deep/ .el-dialog__body {
  padding: 0;
}
.main-box /deep/ .el-dialog__headerbtn {
  // background-color: black;
  right: 30px;
}
.open {
  width: 360px;
  height: 420px;
  // border: 1px solid springgreen;
  // background-image: url("../assets/img/蒙版组 14.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  span {
    width: 100%;
    height: 29px;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: black;
    line-height: 29px;
    letter-spacing: 1px;
    // background-color: steelblue;
  }
  img {
    width: 180px;
    height: 180px;
    border: 2px solid #ffffff;
  }
  p {
    width: 170px;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: black;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
.main-box {
  width: 100%;
  font-size: 36px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  position: relative;
  .bottom-box {
    // height: 100%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 1200px;
    h2 {
      width: 576px;
      // height: 94px;
      font-size: 30px;
      font-weight: 700;
      color: #ffffff;
      line-height: 44px;
      letter-spacing: 4px;
      // border: 1px solid #fff;
    }
    p {
      margin-top: 12px;
      width: 576px;
      height: 33px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
      // border: 1px solid #fff;
      letter-spacing: 2px;
    }
    .btn {
      position: absolute;
      bottom: -70px;
      // left: 135px;
      width: 145px;
      height: 50px;
      border: 1px solid #fff;
      border-radius: 5px;
      background-color: rgb(73, 109, 155);
      color: #fff;
      line-height: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .position {
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // border: 1px solid #fff;
    z-index: 10;
  }
}
</style>